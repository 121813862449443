<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <h1>Matching Requests</h1>
      <table v-if="requests.length > 0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Client</th>
            <th>Category</th>
            <th>Price Range</th>
            <th>Bedrooms</th>
            <th>Bathrooms</th>
            <th>Area</th>
            <th>Location</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="request in requests" :key="request._id">
            <td>{{ request._id }}</td>
            <td>
              {{ request.Client.FirstName }} {{ request.Client.LastName }}
            </td>
            <td>{{ request.Category }}</td>
            <td>{{ request.Price.join("-") }}</td>
            <td>{{ request.Bedrooms.join("-") }}</td>
            <td>{{ request.Bathrooms.join("-") }}</td>
            <td>{{ request.Unit.join("-") }}</td>
            <td>{{ request.locationGoogle[0]["description"] }}</td>
            <td>{{ request.RequestStatus }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>No matching requests found</div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["requests"],
};
</script>
  
  <style scoped>
.matching-requests-container {
  margin: 20px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 8px;
  max-width: 1500px;
  max-height: 90%;
  overflow-y: auto;
}

h1 {
  color: #333;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table,
th,
td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.details-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
  