<template>
  <div class="property-list-container">
    <h1>Private Owner Listings</h1>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Price</th>
          <th>City</th>
          <th>Condition</th>
          <th>Category</th>
          <th>Transaction</th>
          <th>Contacted</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="property in properties" :key="property.id">
          <td>{{ property.id }}</td>
          <td>{{ property.property.title }}</td>
          <td>{{ property.property.price }}</td>
          <td>{{ property.property.city }}</td>
          <td>{{ property.property.condition }}</td>
          <td>{{ property.property.category }}</td>
          <td>{{ property.transaction }}</td>
          <td>{{ property.contacted ? "Yes" : "No" }}</td>
          <td>
            <img
              src="@/assets/images/view.png"
              class="icon-button"
              @click="showDetails(property)"
              title="View Details"
            />
            <img
              v-if="!property.contacted"
              src="@/assets/images/report.png"
              class="icon-button"
              @click="reportContact(property)"
              title="Report Contact"
            />
            <img
              src="@/assets/images/match.png"
              class="icon-button"
              @click="getMatchingRequests(property)"
              title="Get Matching Requests"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination-buttons">
      <button @click="previousPage" :disabled="currentPage <= 1">
        Previous
      </button>
      <span
        v-for="page in pages"
        :key="page"
        @click="page !== '...' && gotoPage(page)"
        :class="{ active: currentPage === page, nonclickable: page === '...' }"
      >
        {{ page }}
      </span>
      <button @click="nextPage" :disabled="currentPage >= totalPages">
        Next
      </button>
    </div>

    <property-details
      v-if="selectedProperty"
      :property="selectedProperty"
      @close="selectedProperty = null"
    ></property-details>
    <matching-requests
      v-if="showMatchingRequests"
      :requests="matchingRequests"
      @close="showMatchingRequests = false"
    ></matching-requests>
  </div>
</template>
  
  <script>
import Repository from "@/services/Repository";
import PropertyDetails from "./PropertyDetails.vue";
import MatchingRequests from "./MatchingRequests.vue";
const RealEstateRepo = Repository.get("realEstate");

export default {
  data() {
    return {
      properties: [],
      currentPage: 1,
      totalPages: 10,
      selectedProperty: null,
      matchingRequests: [],
      showMatchingRequests: false,
    };
  },
  computed: {
    pages() {
      const pages = [];
      const total = this.totalPages;
      const current = this.currentPage;
      const wingSpan = 2; // Number of pages before and after the current page

      // Show the first page
      pages.push(1);

      // Logic for creating ellipsis and intermediate page numbers
      let lowerLimit = current - wingSpan;
      let upperLimit = current + wingSpan;

      if (lowerLimit > 2) {
        pages.push("...");
      }

      // Define the start and end pages for the loop
      // Ensure they stay within the lower and upper limits
      let startPage = lowerLimit > 2 ? lowerLimit : 2;
      let endPage = upperLimit < total ? upperLimit : total - 1;

      // Loop through the range and push to pages array
      for (let i = startPage; i <= endPage; i++) {
        if (i !== 1 && i !== total) {
          // Avoid duplicating the first and last page
          pages.push(i);
        }
      }

      if (upperLimit < total - 1) {
        pages.push("...");
      }

      // Show the last page if it is not the first page
      if (total !== 1) {
        pages.push(total);
      }

      return pages;
    },
  },
  methods: {
    async fetchProperties() {
      let resp = await RealEstateRepo.getOwners(this.currentPage);
      this.properties = resp.data.data;
      this.totalPages = resp.data.total_pages;
    },
    async reportContact(property) {
      await RealEstateRepo.reportContact(property.id);
      this.fetchProperties();
    },
    async getMatchingRequests(property) {
      let body = {
        bedrooms: property.property.bedroom,
        bathrooms: property.property.bathroom,
        google_city: property.property.google_city,
        google_neighborhood: property.property.google_neighborhood,
        price: property.property.price,
        category: property.property.category,
      };
      let resp = await RealEstateRepo.getMatchingRequests(body);
      this.matchingRequests = resp.data;
      this.showMatchingRequests = true;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchProperties();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchProperties();
      }
    },
    gotoPage(page) {
      this.currentPage = page;
      this.fetchProperties();
    },
    showDetails(property) {
      this.selectedProperty = property;
    },
  },
  components: {
    PropertyDetails,
    MatchingRequests,
  },
  mounted() {
    this.fetchProperties();
  },
};
</script>
  
<style scoped>
.property-list-container {
  margin: 20px;
}

h1 {
  color: #333;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table,
th,
td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.icon-button {
  cursor: pointer;
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  margin-right: 8px;
}

.pagination-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

button,
span {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: default;
}

span.active {
  font-weight: bold;
  text-decoration: underline;
}
.nonclickable {
  cursor: default;
  color: #aaa;
}
</style>
