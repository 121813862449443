<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <h2 class="modal-title">Property Details</h2>
      <div class="property-details">
        <div class="property-field">
          <a
            target="_blank"
            v-bind:href="'https://xe.gr/p/' + property.property.ref_id"
            >View Listing</a
          >
        </div>

        <div class="property-row">
          <div class="property-field">
            <strong>Title:</strong>
            <div>{{ property.property.title }}</div>
          </div>
          <div class="property-field">
            <strong>Price:</strong>
            <div>{{ property.property.price + " €" }}</div>
          </div>
          <div class="property-field">
            <strong>Price per Unit:</strong>
            <div>{{ property.property.price_per_unit + " €" }}</div>
          </div>
        </div>
        <div class="property-row">
          <div class="property-field">
            <strong>Unit:</strong>
            <div>{{ property.property.area + " τ.μ." }}</div>
          </div>
          <div class="property-field">
            <strong>Bedrooms:</strong>
            <div>{{ property.property.bedroom }}</div>
          </div>
          <div class="property-field">
            <strong>Bathroom:</strong>
            <div>{{ property.property.bathroom }}</div>
          </div>
        </div>
        <div class="property-row">
          <div class="property-field">
            <strong>City:</strong>
            <div>{{ property.property.city }}</div>
          </div>
          <div class="property-field">
            <strong>Neighborhood:</strong>
            <div>{{ property.property.neighborhood }}</div>
          </div>
          <div class="property-field">
            <strong>Condition:</strong>
            <div>{{ property.property.condition }}</div>
          </div>
        </div>
        <!-- More detailed example for custom handling like arrays or booleans -->
        <div class="property-row">
          <div class="property-field">
            <strong>Heating Medium:</strong>
            <div>{{ property.property.heating_medium.join(", ") }}</div>
          </div>
          <div class="property-field">
            <strong>Furnished:</strong>
            <div>{{ property.property.furnished ? "Yes" : "No" }}</div>
          </div>
          <div class="property-field">
            <strong>Parking:</strong>
            <div>{{ property.property.parking ? "Yes" : "No" }}</div>
          </div>
        </div>
        <div class="property-row">
          <div class="property-field">
            <strong>Owner Phone Number:</strong>
            <div>{{ property.owner_phone_number }}</div>
          </div>
          <div class="property-field">
            <strong>Has been contacted:</strong>
            <div>{{ property.contacted ? "Yes" : "No" }}</div>
          </div>

          <div v-if="property.contacted" class="property-field">
            <strong>Contacted By:</strong>
            <div>
              {{
                property.contacted_by.first_name +
                " " +
                property.contacted_by.last_name
              }}
            </div>
          </div>
        </div>
      </div>
      <button class="close-button" @click="$emit('close')">Close</button>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["property"],
};
</script>
  
  <style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 8px;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto;
}

.property-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.property-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
}

.property-field {
  flex: 1;
  margin-right: 10px;
}

.property-field:last-child {
  margin-right: 0;
}

strong {
  display: block;
  margin-bottom: 5px;
}

.close-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
  